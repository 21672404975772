@import "themes/dark";
@import './colors.scss';
@import 'general/scrollbars';



/* loading spinner */


.lds-ring {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  margin: 3px;
  border: 2px solid #4e5dea;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #4e5dea transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}








.App {
  // text-align: center;
  overflow: hidden !important;
}


.facet-accordion-toggles {
  background-color: white;
  border-top: 1px solid $delimitationAccentTwo;
  border-bottom: 1px solid $delimitationAccentTwo;
}

.facet-accordion-toggles-sorting-icons {
  background-color: white;
  border-top: 1px solid $delimitationAccentTwo;
  border-bottom: 1px solid $delimitationAccentTwo;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.publication-list-wrapper {
  overflow-y: scroll;
  text-align: left;
  color: #2c3645 !important;
}

body {
  overflow-x: hidden;
}


.container-fluid {
  padding: 0 !important;
}

.row {

}

.MainNav {
  // height: 8vh;
}

.MainNavItem {
  padding-top: 20px;
}

.SubNav {
  background-color: $delimitationAccentOne;
}

.activeFilterSetWrapper {
  border: 1px solid $color-brand-primary !important;
  border-radius: 15px !important;
  padding-left: 10px;
  padding-right: 5px;
}


.Dashboard {
  // border: 1px solid black;
  height: 88vh;
}

.react-grid-item {
  border: 1px solid $delimitationAccentOne;
}


// grid items:
.grid-item-container-content {
  // border-top: 1px solid $vissights-lightgrey;
}

.grid-item-container-right-controls {
  border: 1px solid $delimitationAccentTwo;
  overflow: hidden;
  text-align: left;
  padding-left: 2px;
  padding-right: 4px;
  cursor: pointer;
}

// legend items.
.legend-right-toggle-container {
  width: 15px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  top: 0;
  background-color: $color-grey-borders-med;

}

.legend-right-toggle-container:hover {
  width: 30px !important;
  transition: all ease-in-out $standard-duration;
  transform: translateX(-15px);
  // background-color: $delimitationAccentThree;

  .legend-right-toggle-icon-wrapper {
    background-color: $color-brand-secondary;
    width: 40px !important;
    transform: translateX(-10px);
  }
}

.legend-right-toggle-icon-wrapper {
  background-color: $delimitationAccentThree;
  height: 40px;
  width: 30px;
  position: absolute;
  top: 30px;
}

.legend-right-toggle-icon {
  position: absolute;
  top: 8px;
  left: -4 px;
}



.legend-right-circle {
  background: $color-brand-secondary;
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-size: 14px;
  font-weight: bold;
  display: flex; /* or inline-flex */
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 65px;
  right: 75px;
}













// search adjustments modal
.modal-search-adjustments {
  margin-top: 25px !important;
  margin-left: 800px !important;
  width: 275px !important;
  text-align: left !important;
}

.modal-create-poi {
  margin-top: 40vh !important;
  margin-left: 40vw !important;
  width: 275px !important;
  text-align: left !important;
}


.modal-topic-distribution {
  margin-top: 300px !important;
  margin-left: 220px !important;
  width: 470px !important;
  height: 550px !important;
  text-align: center !important;
}

.modal-download-report {
  // margin-top: 10vh !important;
  width: 80vw !important;
  height: 80vh !important;
  text-align: center !important;
  border: 1px solid $white-text;
  max-width: 100vw !important;
}

#downloadModal .modal-content {
  width: 100vw !important;

}

#downloadModal {
  margin-left: 15vw !important;
  margin-right: 0vw !important;
 // margin-top: 10vh !important;
}

.modal-item-not-selectable {
  margin-top: 100px !important;
  margin-left: 220px !important;
  width: 300px !important;
  height: 150px !important;
  text-align: center !important;
  border: 1px solid white;
}


.modal-max-items {
  margin-top: 400px !important;
  margin-left: 800px !important;
  width: 200px !important;
  text-align: left !important;
}


.modal-saved-screenshot {
  margin-top: 40vh !important;
  margin-left: 50vw !important;
  width: 200px !important;
  height: 200px !important;
  text-align: left !important;
}


.modal-header-search-adjustments {
  padding: 8px !important;
}

.modal-body-search-adjustments {
  padding: 8px !important;
}

.modal-footer-search-adjustments {
  padding: 6px !important;
}

.modal-backdrop.show {
  opacity: 0 !important;
}


.rightNavItemActive {
  cursor: grab;
  background-color: $accent-light-blue !important;
  height: 70px !important;
  border: 1px solid lightgrey !important;
}

.rightNavItem {
  cursor: grab;
  height: 70px !important;
  border: 1px solid lightgrey !important;
}

.button-nav {
  height: 30px;
  padding: 0 5px 0 5px !important;
}


.login-span {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  padding: 0 4px 0 5px !important;
}
.iconWrapper{
  display: table;

  width: 25px;
  height: 25px;
  //background-color: lightgreen;
  border: 1px solid black;
  border-radius: 999px;

  i{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
}


.activeSortingIcon {
  color: $color-brand-primary !important;
  cursor: pointer;
}

.inactiveSortingIcon {
  color: $delimitation !important;
  cursor: pointer;
}

//  facetToggleHasFilter' : 'pl-0 pr-0 facetToggleHasNoFilter

.facetToggleHasFilter {
  background-color: $color-brand-primary;
}

.facetToggleHasNoFilter {
  background-color: white;
}

// publication list chart

.publication-list-accordion-toggles {
  border: 1px solid $delimitation;
}

.right-controls-toggle {
  cursor: pointer;
}

// special case for context menu
.context-menu-modal .modal-dialog .modal-content{
  // we must use rgba. Opacity is only used to display none for all decendants too...
  background: rgba(0, 0, 0, 0) !important;
  border: rgba(0, 0, 0, 0) !important;
  width: 220px;
}

#context-menu-modal{
  background: rgba(0, 0, 0, 0) !important;
}



// PRISMA Generator CSS

