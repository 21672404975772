$color-brand-primary: #087fba;
$color-brand-secondary: #F39200;
$color-grey-borders-med: #2c3645;
$accent-one: #1046b3;
$accent-light-blue: #57c5f7;


//dark colors:
$delimitation: #000000;
$delimitationAccentOne: #dddcdc;
$delimitationAccentTwo: #b7b4a9;
$delimitationAccentThree: #444444;
$delimitationAccentFour: #777777;
$delimitationAccentFive: #eee;

// animations

$standard-duration: .2s;



$bg-dark-mode: #161b22;
$grey-dark-mode: #343a4a;
$white-text: #fff;
$light-accent-dark-mode: #96dbfa;