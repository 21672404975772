@import './../colors.scss';

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $delimitationAccentOne;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-brand-primary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-brand-primary;
}


/*** Firefox ***/
:root {
  // scrollbar-face-color: $color-brand-secondary; /* Firefox 63 compatibility */
  // scrollbar-track-color: $color-grey-borders-med; /* Firefox 63 compatibility */
  scrollbar-width: thin;
  scrollbar-color: $color-brand-primary $delimitationAccentOne;
}
