@import './../colors.scss';

// select the dark-mode class which is appended to the body element.
body.dark-mode {

  button {
    color: #EBECF0 !important;
  }

  // make card boarder white on dar theme
  .card{
    border: 1px solid white;
  }

  // set background color and text color of body.
  background-color: $bg-dark-mode;
  color: $white-text;

  // and set background of the most items
  svg, div, a, button, .dropdown-item {
    background-color: $bg-dark-mode !important;
  }

  .MainNav {
    // height: 8vh;
    padding-bottom: 2px;
    border-bottom: 1px solid $grey-dark-mode !important;
  }

  // main nav reporting icon and button text.
  .svgIconReporting > svg > * {
    stroke: $white-text !important;
  }

  .svgIconReporting:hover {
    color: $white-text !important;
  }


  text, a, b, #text, span, button, img, button:hover {
    color: $white-text;
  }

  text.fillWhiteOnDarkMode, text.label {
    fill: $white-text;
  }

  .modal-search-adjustments {
    max-height: 325px !important;
  }

  .modal-create-poi {
    max-height: 200px !important;
  }

  .active.row {
    background-color: $grey-dark-mode !important;
  }

  .activeFacet {
    background-color: $grey-dark-mode !important;
  }


  .active-poi {
    background-color: $grey-dark-mode !important;
  }


  .badge.badge-primary {
    background-color: $grey-dark-mode !important;
  }

  .badge.badge-light {
    background-color: $bg-dark-mode !important;
  }

  .dropdown-menu.show {
    border: 1px solid $white-text;
  }


  // refers to all sorting icons.
  .activeSortingIcon {
    color: $accent-light-blue !important;
  }

  .inactiveSortingIcon {
    color: $white-text !important;
  }


  // refers to rightnav inside charts
  .rightNavItemActive {
    background-color: $grey-dark-mode !important;
    cursor: grab;
    stroke: white !important;
    fill: white !important;
  }


  .rightNavItemActive > svg > * {
    stroke: $accent-light-blue !important;
    background-color: $grey-dark-mode !important;
  }

  .rightNavItemActive > svg {
    background-color: $grey-dark-mode !important;
  }


  .rightNavItem > svg > * {
    stroke: white !important;
  }

  .rightNavItem {
    background-color: $bg-dark-mode !important;
    cursor: grab;
  }

  .legend-right-circle,{
    background: $accent-light-blue !important;
    color: $bg-dark-mode !important;
  }



  .SubNav {
    background-color: $bg-dark-mode !important;
    //border-bottom: 1px solid $white-text !important;
  }

  .activeFilterSetWrapper {
    border: 1px solid $light-accent-dark-mode !important;
    border-radius: 15px !important;
    padding-left: 10px;
    padding-right: 5px;
  }


  .dropdown-item.active {
    background-color: $grey-dark-mode !important;
  }

  .dropdown-item:hover {
    background-color: $grey-dark-mode !important;
  }


  .form-control-sm {
    background-color: $grey-dark-mode !important;
    color: $white-text;
  }


  // sliders and toggles.
  .rc-slider-step, .react-switch-bg {
    background-color: $grey-dark-mode !important;
  }

  .react-switch-handle {
    background-color: $light-accent-dark-mode !important;
  }

  .topic-distribution-text {
    fill: $white-text;
  }

  .topic-distribution-rect {
    fill: $color-brand-primary !important;
  }

  // right controls
  .right-controls-toggle {
    background-color: $grey-dark-mode !important;
  }


  // charts

  // publication list

  .publication-list-accordion-toggles {
    border: 1px solid white;
  }

  .whiteOnDarkMode {
    color: $white-text;
    fill: $white-text;
  }

  .facetToggleHasFilter {
    background-color: $light-accent-dark-mode !important;
  }

  .facetToggleHasNoFilter {
    background-color: $grey-dark-mode;
  }


  // scrollbars

  .btn-light.focus, .btn-light:focus {
    color: $white-text !important;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $bg-dark-mode;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $grey-dark-mode;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $grey-dark-mode;
  }


  /*** Firefox ***/
  :root {
    // scrollbar-face-color: $color-brand-secondary; /* Firefox 63 compatibility */
    // scrollbar-track-color: $color-grey-borders-med; /* Firefox 63 compatibility */
    scrollbar-width: thin;
    scrollbar-color: $grey-dark-mode $bg-dark-mode;
  }


  //  reporting module

  .quill > * {
    background-color: $white-text !important;
    color: $delimitation !important;

    svg, button, .ql-picker > *, .ql-picker-item {
      background-color: $white-text !important;
      color: $delimitation !important;
    }

    .ql-editor {
      color: $white-text;
    }
  }


  .ql-tooltip.ql-hidden {
    display: none !important;
  }



  // context menu needs no background!
  .context-menu-modal {
    background: rgba(0, 0, 0, 0) !important;
  }

  .context-menu-modal {
    background: rgba(0, 0, 0, 0) !important;
  }
  .modal-body, .modal-body svg {
    background: rgba(0, 0, 0, 0) !important;
  }


  // PRISMA GENERATOR DARK THEME CSS

  div {
    color: $white-text !important;
    //
  }

  .react-flow__node-default, .react-flow__node {
    border: 1px solid $white-text;
  }

  .react-flow__controls > * {
    color: $white-text !important;
    // stroke: $white-text !important;
    fill: $white-text !important;
  }

}